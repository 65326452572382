








































import { Component, Vue } from 'vue-property-decorator';
import label from '@/resources/labels/notfound.json';

@Component
export default class NotFoundPage extends Vue {
  private readonly label: any = label;
}
